<template>
  <div>
    <p class="text-2xl mb-6">Total productos entre fechas</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <select-puesto
              :storePuesto="false"
              @getPuesto="getPuesto"
              :dense="true"
              :outlined="true"
              :modulos="[$route.meta.modulo]"
              :filtros="{id_reporte:6}"
            ></select-puesto>
          </v-col>
          <v-col cols="12" sm="6">
            <dos-fechas @getFechas="getFechas"></dos-fechas>
          </v-col>
          <v-col cols="12" sm="6">
            <SelectCategoria :clearable="true" v-model="categoria" :add="false" :dense="true" :outlined="true" />
          </v-col>
          <v-col cols="12" sm="6">
            <SelectSubCategoria
              :clearable="true"
              v-model="sub_categoria"
              :padre="categoria"
              :add="false"
              :dense="true"
              :outlined="true"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BuscarProductoVue :label="'Productos Ingredientes'" @GetProducto="GetProducto" />
            <small>Busca los productos de ventas que contenga producto como ingrediente.</small>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
            label="Nombre producto/ referencia "
            v-model="valor"
            outlined
            dense
          ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn  :loading="cargando" :disabled="cargando || !validar()" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <tabla-reporte
          @GetVer="GetVerComanda"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
        ></tabla-reporte>
      </v-card-text>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>TOTAL</td>
                <td>{{ funciones.formatoNumeric(totales.total) }}</td>
              </tr>
              <tr>
                <td>IVA</td>
                <td>{{ funciones.formatoNumeric(totales.iva) }}</td>
              </tr>
              <tr>
                <td>TOTAL+IVA</td>
                <td>{{ funciones.formatoNumeric(totales.total_iva) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaReporte from '@/components/TablaReporte.vue'
import { ref } from '@vue/composition-api'
import DosFechas from '@/components/DosFechas.vue'
import SelectPuesto from '../../../archivo/puesto/components/SelectPuesto.vue'
import MaestroService from '@/api/servicios/MaestroService'
import funciones from '@/funciones/funciones'
import SelectCategoria from '@/views/sistema/inventario/maestro/categoria/componentes/Select.vue'
import BuscarProductoVue from '../../../../inventario/producto/componentes/BuscarProducto.vue'
import SelectSubCategoria from '@/views/sistema/inventario/maestro/sub-categoria/componentes/Select.vue'
import moment from 'moment'
import store from '@/store'
// demos

export default {
  components: { TablaReporte, DosFechas, SelectPuesto, SelectCategoria, SelectSubCategoria, BuscarProductoVue },
  setup() {
    const nombreReporte = ref(`Total productos entre fechas`)
    const valor = ref('')
    const cabeceraExcel = ref([
      {
        puesto: 'Locatario/Area',
        desde: 'Desde',
        hasta: 'Hasta',
        nombre: 'Producto',
        cant: 'Cant',
        total: 'Total',
      },
    ])

    const cabecera = ref([
      {
        text: 'Locatario/Area',
        align: 'start',
        sortable: false,
        value: 'puesto',
      },
      { text: 'Desde', value: 'desde' },
      { text: 'Hasta', value: 'hasta' },
      { text: 'Producto', value: 'nombre' },
      { text: 'Categoria', value: 'categoria' },
      { text: 'Sub Categoria', value: 'sub_categoria' },
      { text: 'Cant', value: 'cant' },
      { text: 'Total', value: 'total' },
      { text: 'Iva', value: 'iva' },
      { text: 'Total+Iva', value: 'total_iva' },
    ])
    const categoria = ref(null)
    const sub_categoria = ref(null)
    const datos = ref([])
    const fechas = ref({
      desde: '',
      hasta: '',
    })
    const cargando = ref(false)
    const puesto = ref({})
    const validar = () => {
      let validado = true
      if (puesto.value.id == undefined) {
        validado = false
      }
      if (fechas.value.desde == undefined || fechas.value.desde == '') {
        validado = false
      }

      return validado
    }
    const productoSelect = ref(null)
    const GetProducto = dato => {
      productoSelect.value = dato.id
    }

    const totales = ref({
      total: 0,
      iva: 0,
      total_iva: 0,
    })
    const totalizar = () => {
      let totales_ = {
        total: 0,
        iva: 0,
        total_iva: 0,
      }
      datos.value.forEach(element => {
        totales_.total += element.total
        totales_.iva += element.iva
        totales_.total_iva += element.total_iva
      })

      totales.value = totales_
    }
    const buscarDatos = () => {
      cargando.value = true

      var parametros = {
        desde: fechas.value.desde,
        hasta: fechas.value.hasta,
        id_puesto: puesto.value.id,
        id_categoria: categoria.value,
        id_sub_categoria: sub_categoria.value,
        id_producto: productoSelect.value,
        valor : valor.value
      }
      MaestroService.ReporteVentasXProductoFechas(parametros)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            nombreReporte.value = `Total productos entre fechas ${puesto.value.nombre} ${fechas.value.desde} al ${fechas.value.hasta}`
            totalizar()
            if (datos.value.length == 0) {
              store.commit('setAlert', {
                message: 'No hubo resultado a la busqueda',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'waring',
            })
          }
        })
        .catch(err => {})
        .finally(() => {
          cargando.value = false
        })
    }

    const getFechas = datos => {
      fechas.value = datos
    }
    const getPuesto = datos => {
      puesto.value = datos
    }
    return {
      cabecera,
      datos,
      getFechas,
      getPuesto,
      fechas,
      puesto,
      validar,
      buscarDatos,
      cargando,
      cabeceraExcel,
      nombreReporte,
      categoria,
      sub_categoria,
      totalizar,
      totales,
      funciones,
      GetProducto,
      valor
    }
  },
}
</script>
