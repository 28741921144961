<template>
  <div>
    <v-autocomplete
      :prepend-icon="icon ? icons.mdiAccount : null"
      v-model="model"
      :items="items"
      :filter="customFilter"
      :loading="isLoading"
      :search-input.sync="search"
      clearable
      hide-details="auto"
      hide-selected
      item-text="nombre"
      item-value="id"
      :label="label"
      outlined
      dense
      :rules="rules"
    >
    
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title> No hay producto </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item }">
        <span>{{ item.nombre }} </span>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-avatar color="primary" size="30" class="font-weight-light white--text">
          <img :src="`${config.api_imagenes}/assets/images/product/${item.img}`" :alt="item.nombre.charAt(0)" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.nombre }}
            <br />
            <small> {{ item.id }} / {{ item.referencia }}</small>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action-text>
          <small>{{ item.producto_tipo.descripcion }}</small>
        </v-list-item-action-text>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAccount } from '@mdi/js'
import ProductosService from '@/api/servicios/ProductosService' 
import config from '@/api/config'
export default {
  props: {
    icon: Boolean,
    rules: Array,
    label: {
      type: String,
      default: ' Buscar producto ',
    },
    filtros: Object,
  },
  setup(props, context) {
    const dialog = ref(false)
    const isLoading = ref(false)
    const items = ref([])
    const model = ref(null)
    const search = ref(null)
    const tab = ref(null)
    const customFilter = (item, queryText, itemText) => {
      const filtro1 = item.referencia.toUpperCase()
      const filtro2 = item.nombre.toUpperCase()
      const filtro3 = item.descripcion.toUpperCase()
      const filtro4 = item.id.toString().toUpperCase()
      const searchText = queryText.toUpperCase()

      return (
        filtro1?.indexOf(searchText) > -1 ||
        filtro2?.indexOf(searchText) > -1 ||
        filtro3?.indexOf(searchText) > -1 ||
        filtro4?.indexOf(searchText) > -1
      )
    }

    watch(model, () => {
      context.emit(
        'GetProducto',
        items.value.find(item => item.id == model.value),
      ) 
    })
    watch(search, () => {
      Buscar() 
    })
    const limpiar = () => {
      model.value = null
      search.value = null
      items.value = []
    }
    var asignarPagoductoId = null
    var asignarPagoductoNombre = null

    const asignarPagoducto = async item => {
      asignarPagoductoNombre = item.nombre
      asignarPagoductoId = item.id

      Buscar()
    }
    const Buscar = () => {
      // Items have already been loaded
      /*if (items.value.length > 0)
                return*/

      if (isLoading.value == true) return

      isLoading.value = true
      setTimeout(() => {
        // Lazily load input items
        var filtro = { ...props.filtros }
        if (search.value) {
          filtro = { ...filtro, valor: search.value }
        }

        if (asignarPagoductoId) {
          filtro = { ...filtro, valor: asignarPagoductoNombre }
        }
        ProductosService.productoListarBusqueda({ ...filtro })
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              items.value = response.data.datos

              if (asignarPagoductoId) {
                model.value = asignarPagoductoId
                asignarPagoductoId = null
                asignarPagoductoNombre = null
              }
            } else {
              items.value = []
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (isLoading.value = false))
      }, 1000)
    }

    return {
      icons: {
        mdiAccount,
      },
      dialog,
      isLoading,
      items,
      model,
      search,
      tab,
      customFilter,
      config,
      limpiar,
      asignarPagoducto,
    }
  },
}
</script>